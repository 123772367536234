import React from "react";

export const TwitterBlack = () => {
  return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <path
        d="M30,0A30,30,0,1,1,0,30,30,30,0,0,1,30,0ZM24.72,46C38,46,45.25,35,45.25,25.42c0-.31,0-.63,0-.93a14.59,14.59,0,0,0,3.61-3.74,14.67,14.67,0,0,1-4.14,1.14,7.23,7.23,0,0,0,3.17-4,14.18,14.18,0,0,1-4.58,1.75,7.22,7.22,0,0,0-12.48,4.94A6.84,6.84,0,0,0,31,26.23a20.46,20.46,0,0,1-14.87-7.54,7.19,7.19,0,0,0,2.24,9.62,7.06,7.06,0,0,1-3.26-.9v.09a7.22,7.22,0,0,0,5.79,7.08,7,7,0,0,1-1.9.26,6.82,6.82,0,0,1-1.36-.14,7.18,7.18,0,0,0,6.73,5,14.45,14.45,0,0,1-9,3.09,12.13,12.13,0,0,1-1.72-.11A20.06,20.06,0,0,0,24.72,46Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
