import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Close } from "../../images/icons/close";
import Button from "../Button";

import "./styles.scss";

interface SectionInterface {
  children: any;
  isOpen: boolean;
  onClose: any;
}

const ArticleProductsPopup : FunctionComponent<SectionInterface> = ({
  children,
  isOpen,
  onClose
}) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleScroll = () => {
      onClose();
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAddClass(true);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  if (!isOpen) return null;

  return (
    <div  className={`article-popup ${addClass ? 'popup-open' : ''}`} ref={popupRef}>
      <div className="article-popup-content">
        {children}
        <Button
          variant="primary"
          className="article-popup-close-btn"
          onClick={onClose}
        >
        <Close aria-label="reset icon" stroke="black" />
        </ Button>
      </div>
    </div>
  )
}

export default ArticleProductsPopup