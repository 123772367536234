import React from "react";

export const Close = (props: any)  => {
  return (
    <svg width="33px" height="33px" viewBox="0 0 74 74" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-14.000000, -162.000000)">
          <rect x="0" y="0"/>
          <g
            transform="translate(51.000000, 199.000000) rotate(-225.000000) translate(-51.000000, -199.000000) translate(26.000000, 174.000000)"
            fill={props.stroke || '#000'}
            stroke={props.stroke || '#000'}
            strokeLinecap="round"
            strokeWidth="4"
          >
            <line x1="25.0681974" y1="0.325957817" x2="24.4318013" y2="49.3284565"/>
            <line x1="49.2512487" y1="24.5090091" x2="0.885146112" y2="24.5090091"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
