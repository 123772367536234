import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

const articleView = (pageTitle: string) => {
  const aiGenerated = document.querySelector('meta[property="Samantha"]') ? true : false;

  const win = window as any;
  if (!win.digitalData.component) win.digitalData.component = [];

  win.digitalData.component.push({
    componentInfo: {
      componentID: `${aiGenerated ? 'Samantha Content' : pageTitle}`,
      componentName: `${aiGenerated ? 'Samantha Content' : pageTitle}`,
    },
  });

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.articleView,
      eventLabel: `${pageTitle}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Read"
  };
  pushEvent(EventData);
  logEvent("Article Details view", EventData);
};

export const event59 = (pageTitle:string) => {
  let event59Timer:any;
  event59Timer = setInterval(() => {
    if(window.isEvent1){
      articleView(pageTitle);
      clearTimeout(event59Timer);
    }
  }, 500)
}