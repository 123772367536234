import React from "react";

export const FacebookBlack = () => {
  return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 59.64">
      <path
        d="M60,30.18A30,30,0,1,0,25.31,59.82v-21H17.7V30.18h7.61V23.57c0-7.52,4.48-11.67,11.33-11.67a45.79,45.79,0,0,1,6.72.59v7.38H39.58c-3.73,0-4.89,2.31-4.89,4.68v5.63H43l-1.33,8.67h-7v21A30,30,0,0,0,60,30.18Z"
        transform="translate(0 -0.18)"
      />
    </svg>
  );
};
