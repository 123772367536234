import React, { FunctionComponent, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet';
import './styles.scss'

interface TikTokEmbedInterface {
  url: string;
  width?: number;
}


const TikTokEmbed:FunctionComponent<TikTokEmbedInterface> = ({url, width = 0}) => {
  const tikTokRef = useRef('');

  useEffect(() => { 
    getTikTokData();
  }, [url, tikTokRef])

  async function getTikTokData(){
    const embedUrl = `https://www.tiktok.com/oembed?url=${url}`
    const data = await fetch(embedUrl);
    const response = await data.json();
    tikTokRef.current.innerHTML = response.html;
  }

  return (
    <>
      <Helmet>
        <script defer src='https://www.tiktok.com/embed.js'></script>
      </Helmet>
      <div ref={tikTokRef} style= {{ maxWidth: width || 'fit-content'}}>

      </div>
    </> 
  )
}

export default TikTokEmbed