import React from "react";

export const WhatsAppBlack = () => {
  return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.72 59">
      <path
        d="M50.65,9.42a29.24,29.24,0,0,0-46,35.28L.49,59.85,16,55.78a29.19,29.19,0,0,0,14,3.56h0A29.25,29.25,0,0,0,50.65,9.42ZM30,54.4h0A24.16,24.16,0,0,1,17.6,51l-.89-.52L7.51,52.9l2.46-9L9.39,43A24.3,24.3,0,1,1,30,54.4Z"
        transform="translate(-0.49 -0.85)"
        fillRule="evenodd"
      />
      <path
        d="M43.3,36.2c-.73-.36-4.32-2.13-5-2.37s-1.16-.37-1.64.36-1.89,2.38-2.32,2.87-.85.54-1.58.18a20.09,20.09,0,0,1-5.87-3.63,21.85,21.85,0,0,1-4.07-5.06c-.42-.73,0-1.12.32-1.49s.73-.85,1.1-1.28A4.88,4.88,0,0,0,25,24.56a1.37,1.37,0,0,0-.06-1.28c-.18-.36-1.65-4-2.25-5.42s-1.2-1.23-1.65-1.25-.91,0-1.4,0a2.7,2.7,0,0,0-1.95.91,8.2,8.2,0,0,0-2.55,6.1c0,3.59,2.62,7.07,3,7.55s5.15,7.87,12.48,11a38.57,38.57,0,0,0,4.16,1.54,10,10,0,0,0,4.61.29c1.4-.21,4.32-1.76,4.93-3.47a6.18,6.18,0,0,0,.42-3.47C44.52,36.75,44,36.57,43.3,36.2Z"
        transform="translate(-0.49 -0.85)"
        fillRule="evenodd"
      />
    </svg>
  );
};
