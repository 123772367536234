import React, { FunctionComponent, useEffect, useContext } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import { useInView } from "react-intersection-observer";
import "./styles.scss";
import Section from "../Section";
import SliderBlock from "../SliderBlock";
import ProductRatingsAndReviewScript from "../ProductRatingsAndReviewScript";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";

export interface ProductSliderV2Interface {
  heading?: string;
  bgColor?: {
    value: string;
    title: string;
  };
  template?: string;
  preferPerformance?: boolean;
  isVariant?: boolean;
  content: {
    _id: string;
    _rawPaImages: ImageInterface[];
    title: string;
    url: string;
    _rawImage: ImageInterface;
    SubBrand: {
      name: string;
    };
    variant?: {
      primaryColor?: {
        hex?: string;
      };
    };
    name: string;
    displayReviews: boolean;
    slug: {
      current: string;
    };
    productCategory: {
      name: string;
    };
    paUPC: string;
    paRetailerCategory: string;
    paSmartProductId: string;
    // For Preview
    images?: ImageInterface[];
  }[];
}

const ProductSliderV2: FunctionComponent<ProductSliderV2Interface> = props => {
  const { heading, bgColor, content, preferPerformance, isVariant } = props;

  const titleColor = bgColor?.title?.toLowerCase();
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);
  const { sanitySiteSettings } = useContext(LocalizedContext);

  const colorList: { [key: string]: string } = {
    primary: "var(--primary-color)",
    secondary: "var(--secondary-color)"
  };

  const [ref, ratingInView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  useEffect(() => {
    event11(content, sanitySiteSettings?.brandName, "Product Slider");
  }, [sanitySiteSettings?.brandName, content]);
  useEffect(() => {
    setIsDarkTheme(document && document.querySelectorAll(".dark-theme").length > 0 ? true : false);
  }, []);
  // const darkThemeSubstrings = ["/about-us/our-nyfw-stylists/"]; // Adicione os caminhos que devem ter o tema escuro
  // const isDarkTheme = typeof window !== "undefined" && darkThemeSubstrings.some(substring => window.location.pathname.includes(substring));
  return (
    <Section
      heading={heading}
      theme={{ title: bgColor?.title || bgColor?.value || "" }}
      // className="product-slider"
      className={`product-slider ${isDarkTheme ? "dark-theme" : ""}`}
      data-testid="product-slider"
    >
      <div ref={ref}>
        {ratingInView && (
          <>
            <ProductRatingsAndReviewScript />
            <SliderBlock
              isVariant={isVariant}
              data={content}
              textColor={colorList[titleColor as string]}
              type="productV2"
              preferPerformance={preferPerformance}
            />
          </>
        )}
      </div>
    </Section>
  );
};

export default ProductSliderV2;
