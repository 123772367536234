import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./styles.scss";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, SanityColorList, Seo, Slug } from "../../types/SanityTypes";
import RichText from "../RichText";
import SocialShare from "../SocialShare";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { event64 } from "../../analytics/event64";

import Picture from "../Picture";
import { smoothScroll } from "../../utils/helper";

import { LandingSectionsInterface } from "../../types/SanityTypes";
import BlockRenderer from "../BlockRenderer";
import ContainerOne from "../ContainerOne";
import ContainerTwo from "../ContainerTwo";
export interface LookInterface {
  data: {
    templateOne?: boolean;
    templateTwo?: boolean;
    subheading?: string;
    productsRecommendation: any;
    headline: string;
    dontshowheadline?: boolean;
    _id: string;
    tripleHeadline: boolean;
    _rawHeroImage: ImageInterface;
    _rawTextBody?: PortableText;
    publishedAt?: string;
    slug: {
      current: string;
    };
    name: string;
    parentPage: {
      name: string;
    };
    parentSection?: Slug & {
      _id: string;
      name: string;
      main: {
        sectionColor: SanityColorList;
      };
      parentSection?: Slug & {
        name: string;
        main: {
          sectionColor: SanityColorList;
        };
      };
    };
    articleRecommendation: {
      _id: string;
      _rawHeroImage: ImageInterface;
      slug: {
        current: string;
      };
      subheading: string;
      headline: string;
      parentSection: Slug & {
        parentSection?: Slug;
      };
      rawHeroImage?: ImageInterface;
      teaser?: {
        _rawTeaserHeroImage: ImageInterface;
      };
    }[];
    lookRecommendation: {
      _id: string;
      _rawHeroImage: ImageInterface;
      slug: {
        current: string;
      };
      subheading: string;
      headline: string;
      parentSection: Slug & {
        parentSection?: Slug;
      };
      rawHeroImage?: ImageInterface;
      teaser?: {
        _rawTeaserHeroImage: ImageInterface;
      };
    }[];
    landingSections?: LandingSectionsInterface[];
    articleDetails: boolean;
    socialShare: boolean;
    seo: Seo;
    mainVideo?: string;
  };
  articleRecommendationLabel: string;
  articleShareLabel: string;
}

const HIDE_HEADLINES_FOR = ["2a2f7bad-dbca-43f1-8775-507e9867b450"];

const ArticleTitle = ({ articleTitle }: any) => <h1 dangerouslySetInnerHTML={{ __html: articleTitle }} />;

const Look: FunctionComponent<LookInterface> = ({ data, articleRecommendationLabel, articleShareLabel }) => {
  const { language } = useContext(LocalizedContext);
  const { siteUrl } = useSiteMetadata(language);

  const parentPath = data.parentSection?.parentSection
    ? `${data.parentSection?.parentSection.slug.current}/${data.parentSection?.slug.current}`
    : data.parentSection?.slug.current;

  let articleParentPath = "";
  if (parentPath) {
    articleParentPath = getLocalizedPath(parentPath as string, data.parentSection?._id);
  }

  const coverageImage = {
    src: `${data._rawHeroImage?.asset?.url}?w=710&h=496&fit=crop&auto=format&q=80`,
    alt: `${data._rawHeroImage?.alt}`,
    sources: [
      {
        srcset: [
          `${data._rawHeroImage?.asset?.url}?w=710&h=496&fit=crop&auto=format&q=80 1x`,
          `${data._rawHeroImage?.asset?.url}?w=1420&h=992&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (max-width: 767px)`
      },
      {
        srcset: [
          `${data._rawHeroImage?.asset?.url}?w=710&h=496&fit=crop&auto=format&q=80 1x`,
          `${data._rawHeroImage?.asset?.url}?w=1420&h=992&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (min-width: 768px)`
      }
    ],
    maintainAspectRatio: false,
    size: {
      width: 710,
      height: 496
    },
    lqip: data._rawHeroImage?.asset?.metadata?.lqip
  };

  const getAllSubHeadlines = (richText: any): string[] => {
    return richText
      ?.filter((r: any) => r.style === "h2")
      ?.map((i: any) =>
        i.children
          ?.map((c: any) => c.text)
          ?.join("")
          ?.trim()
      );
  };

  useEffect(() => {
    event64(data.headline);
  }, [data.headline]);

  useEffect(() => {
    const scrollToElementWithId = () => {
      window.onload = () => {
        try {
          const urlParams = new URLSearchParams(window.location.search);
          const idParam = urlParams.get("id");

          if (idParam) {
            const targetElement = document.getElementById(idParam);

            if (targetElement) {
              const offset = targetElement.offsetTop;

              window.scrollTo({
                top: offset,
                behavior: "smooth"
              });
            } else {
              console.error(`Element with ID '${idParam}' not found.`);
            }
          } else {
            console.warn("No ID parameter found in the URL.");
          }
        } catch (error) {
          console.error("An error occurred while scrolling to the element:", error);
        }
      };
    };

    scrollToElementWithId();
  }, []);

  const handleScrollToClick = (e: any) => {
    const targetElement = document.getElementById(e.target.textContent.trim());
    const elementPosition = targetElement?.getBoundingClientRect().y;
    if (elementPosition) {
      smoothScroll(elementPosition - 130);
    }
  };

  const handleScrollToKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleScrollToClick(e);
    }
  };

  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const currentUrl = window.location.href;
    const localeIndex = currentUrl.indexOf("/us/en");

    let newBaseUrl = "";

    if (localeIndex !== -1) {
      newBaseUrl = currentUrl.substring(0, localeIndex + 6);
    }

    setBaseUrl(newBaseUrl);
  }, []);

  return (
    <div data-testid="article">
      {!data?.templateOne && !data?.templateTwo && (
        <Container fluid className={`article`}>
          {!data.dontshowheadline && (
            <Row className="article-head">
              <div className="px-2 mx-auto">
                <h1 data-testid="article-headline" className="article-headline">
                  {data.headline}
                </h1>
                {data.subheading && <h2 className="article-subheadline">{data.subheading}</h2>}
              </div>
            </Row>
          )}

          {!HIDE_HEADLINES_FOR.includes(data._id) && (
            <Row>
              <Col>
                <ul className="article-all-headlines text-body-lg">
                  {getAllSubHeadlines(data?._rawTextBody)?.map(headline => (
                    <li
                      key={headline}
                      onClick={handleScrollToClick}
                      onKeyDown={handleScrollToKeyDown}
                      role="button"
                      title="click to scroll to section"
                      aria-label={headline}
                      data-analytics-event2
                      tabIndex={0}
                      className="article-subheadline"
                    >
                      {headline}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
          {data._rawHeroImage?.asset?.url && (
            <Row>
              <Col className="article-picture-wrapper">
                <Picture
                  src={coverageImage.src}
                  alt={coverageImage?.alt}
                  className="article-cover"
                  sources={coverageImage.sources}
                  size={coverageImage.size}
                  maintainAspectRatio={coverageImage.maintainAspectRatio}
                  loading="lazy"
                  lqip={coverageImage.lqip}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <div data-testid="article-body" className="article-body">
                <RichText data={data?._rawTextBody} pageSlug={`${siteUrl}/${data.slug.current}/`} />
                {data.socialShare && (
                  <SocialShare url={`${siteUrl}/${data.slug.current}/`} label={articleShareLabel} locale={language} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {data?.templateOne && (
        <ContainerOne>
          {data?.landingSections[0] && <BlockRenderer key={"landingSection"} section={data.landingSections[0]} />}
          {data?.landingSections[1] && (
            <div className="module-2">
              <div className="text-content">
                <div>
                  <h2>{data?.landingSections[1].titleOne}</h2>
                  <RichText data={data?.landingSections[1]._rawTextBodyOne[0]} />
                </div>
                <div>
                  <h2>{data?.landingSections[1].titleTwo}</h2>
                  <RichText data={data?.landingSections[1]._rawTextBodyTwo[0]} />
                </div>
                <div>
                  <h2>{data?.landingSections[1].titleThree}</h2>
                  <RichText data={data?.landingSections[1]._rawTextBodyThree[0]} />
                </div>
              </div>

              <VideoPlayer props={data} />
            </div>
          )}
          {data?.landingSections[2] && <ImageList stepListing={data?.landingSections[2]} baseUrl={baseUrl} />}
        </ContainerOne>
      )}

      {data?.templateTwo && (
        <ContainerTwo>
          <ArticleTitle articleTitle={data.subheading} />

          {data?.landingSections[0] && <ImageList2 stepListing={data?.landingSections[0]} />}
        </ContainerTwo>
      )}
    </div>
  );
};

const VideoPlayer = ({ props }) => {
  const videoSection = props?.landingSections?.[1]?._rawVideoBanner;

  if (!videoSection || !videoSection.fallback || !videoSection.fallback.asset) {
    return null;
  }

  const { alt, fallback } = videoSection;
  const { url, mimeType } = fallback.asset;

  return (
    <div className="video-content">
      <video controls width="100%" height="auto" alt={alt}>
        <source src={url} type={mimeType} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const StepListingTitle = ({ stepListingTitle }: any) => <h2 dangerouslySetInnerHTML={{ __html: stepListingTitle }} />;

const ImageList2 = ({ stepListing }: any) => {
  return (
    <div className="step-listing">
      <ul>
        {stepListing.content.map(step => (
          <li key={step.id} id={step.id}>
            <span className="border" />

            <div className="image">
              <img src={step._rawImage.asset.url} alt={step.stepDescription} />
            </div>

            <div className="content">
              <h2>{step.title}</h2>
              <div className="links">
                <RichText data={step?._rawLinks} />
              </div>
              <div className="description">
                <RichText data={step?._rawTextBody} />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <a href={stepListing.externalCtaLink}>{stepListing.stepListingCTALabel}</a>
    </div>
  );
};

const ImageList = ({ stepListing, baseUrl }) => {
  return (
    <div className="step-listing">
      <StepListingTitle stepListingTitle={stepListing.title} />
      <p>{stepListing.heading}</p>
      <ul>
        {stepListing.content.map(step => (
          <li key={step.id}>
            <a href={`${baseUrl}${stepListing.link}?id=${step.id}`}>
              <img src={step._rawImage.asset.url} alt={step.stepDescription} />
            </a>
            <p>{step.stepDescription}</p>
          </li>
        ))}
      </ul>
      <a href={stepListing.externalCtaLink}>{stepListing.stepListingCTALabel}</a>
    </div>
  );
};

export default Look;
