import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Product Listing Click
 */
export const event12 = (
  productId: string,
  productName: string,
  productCategoryName: string,
  brand: string,
  listPosition: number,
  componentName: string,
  price?: string,
  quantity?: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (!win.digitalData.product) win.digitalData.product = [];
  if (!win.digitalData.component) win.digitalData.component = [];


  win.digitalData.product[0].productInfo.productID = productId;
  win.digitalData.product[0].productInfo.productName = productName;
  win.digitalData.product[0].productInfo.brand = brand;
  win.digitalData.product[0].productInfo.price = price;
  win.digitalData.product[0].productInfo.quantity = quantity;
  win.digitalData.product[0].productInfo.componentName = componentName;

  win.digitalData.product.push({
    // productInfo: {
    //   productID: productId,
    //   productName,
    //   price,
    //   brand,
    //   quantity,
    //   componentName
    // },
    category: {
      primaryCategory: productCategoryName
    },
    attributes: {
      productVariants: listPosition,
      listPosition
    }
  });

  const EventData = {
    eventInfo: {
      type: EventType.productclick
    },
    subcategory: EventType.interest
  };

  pushEvent(EventData);
  logEvent("Product Listing Click", EventData);
};
