import { EventType, pushEvent } from "./index";

/**
 * Article View
 */
export const event64 = (articleName: string) => {
  const aiGenerated = document.querySelector('meta[property="Samantha"]') ? true : false;

  const win = window as any;
  if (!win.digitalData.component) win.digitalData.component = [];

  win.digitalData.component.push({
    componentInfo: {
      componentID: `${aiGenerated ? 'Samantha Content' : articleName}`,
      componentName: `${aiGenerated ? 'Samantha Content' : articleName}`,
    },
  });

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.articleView,
      eventLabel: articleName,
      eventValue: 1,
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.read,
  });
};
