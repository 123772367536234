import React, { FunctionComponent, useContext } from "react";

import { TwitterBlack } from "../../images/icons/twitterBlack";
import { FacebookBlack } from "../../images/icons/facebookBlack";
import { WhatsAppBlack } from "../../images/icons/whatsAppBlack";

import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { FacebookMessanger } from "../../images/icons/facebookMessanger";

interface SocialShareInterface {
  url: string;
  name?: string;
  locale: string;
  label: string;
}

const SocialShare: FunctionComponent<SocialShareInterface> = ({ url, locale, label }) => {
  const { sanityAccessibility } = useContext(LocalizedContext);

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}%2F&amp;src=sdkpreparse`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const whatsAppUrl = `https://api.whatsapp.com/send?text=${url}`;
  const messangerAppUrl = `fb-messenger://share/?link=${url}`;

  return (
    <div className="c-social-container">
      <p className="c-social-container__title">{label}</p>
      <div className="c-social-container__icons">
        <a
          href={facebookUrl}
          rel="noreferrer"
          aria-label={`Facebook - ${sanityAccessibility?.newWindow}`}
          title={`Facebook - ${sanityAccessibility?.newWindow}`}
          data-analytics-event47
        >
          <FacebookBlack />
        </a>
        <a
          href={twitterUrl}
          rel="noreferrer"
          aria-label={`Twitter - ${sanityAccessibility?.newWindow}`}
          title={`Twitter - ${sanityAccessibility?.newWindow}`}
          data-analytics-event47
        >
          <TwitterBlack />
        </a>
        <a
          href={whatsAppUrl}
          rel="noreferrer"
          aria-label={`Whatsapp - ${sanityAccessibility?.newWindow}`}
          title={`Whatsapp - ${sanityAccessibility?.newWindow}`}
          data-analytics-event47
        >
          <WhatsAppBlack />
        </a>
        <a
          href={messangerAppUrl}
          rel="noreferrer"
          className="c-social-container__icons__messenger"
          aria-label={`Messenger - ${sanityAccessibility?.newWindow}`}
          title={`Messenger - ${sanityAccessibility?.newWindow}`}
          data-analytics-event47
        >
          <FacebookMessanger />
        </a>
      </div>
    </div>
  );
};

export default SocialShare;
