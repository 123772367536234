import React, { FunctionComponent } from "react";
import Picture from "../Picture";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";

export interface ProductFeatureCardInterface {
  title: string;
  description?: string;
  img: ImageInterface;
  preferPerformance?: boolean
}

const ProductFeatureCard: FunctionComponent<ProductFeatureCardInterface> = ({ img, title, description, preferPerformance }) => {
  const productFeatureCardImage = {
    src: `${img?.asset?.url}?w=384&h=232&fit=crop&auto=format&q=80`,
    alt: `${img?.alt}`,
    sources: [
      {
        srcset: [
          `${img?.asset?.url}?w=384&h=232&fit=crop&auto=format&q=80 1x`,
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (max-width: 767px)`
      },
      {
        srcset: [
          `${img?.asset?.url}?w=384&h=232&fit=crop&auto=format&q=80 1x`,
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (min-width: 768px)`
      }
    ],
    maintainAspectRatio: false,
    size: {
      width: 384,
      height: 232
    },
    lqip: img?.asset?.metadata?.lqip
  };
  return (
    <section className="product-feature-card card border-0">
      {img && (
        <Picture
          src={productFeatureCardImage.src}
          alt={productFeatureCardImage.alt}
          className="card-img-top"
          sources={productFeatureCardImage.sources}
          size={productFeatureCardImage.size}
          maintainAspectRatio={productFeatureCardImage.maintainAspectRatio}
          loading={preferPerformance ? "eager" : "lazy"}
          lqip={productFeatureCardImage.lqip}
        />
      )}
      <div className={`product-feature-card__wrapper`}>
        <p className="product-feature-card__title card-title">{title}</p>
        <p className="product-feature-card__description">{description}</p>
      </div>
    </section>
  );
};

export default ProductFeatureCard;
