import React, { FunctionComponent, MouseEvent, useContext } from "react";
import { Link } from "../Link";
import { ImageInterface } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatings from "../ProductRatings";
import ArticleCard from "../ArticleCard";
import LookCard from "../LookCard";
import ProductCard from "../ProductCard";
import { useInView } from "react-intersection-observer";
import CollectionCard from "../CollectionCard";
import ProductFeatureCard from "../ProductFeatureCard";
import TikTokEmbed from "../TikTok";
import "../SliderBlock/styles.scss";

interface SlideInterface {
  id?: string;
  heading?: string;
  description?: string;
  image?: ImageInterface;
  link?: string;
  mainVideo?: string;
  videoUrl: string;
  itemPosition: string;
  type?: string;
  productColor?: string;
  textColor?: string;
  inView?: boolean;
  upc?: string;
  displayReviews?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  isRelatedArticles?: boolean;
  preferPerformance?: boolean
  ctaReadMore?: string;
}

const Slide: FunctionComponent<SlideInterface> = ({
  link,
  itemPosition,
  image = null,
  heading,
  description,
  mainVideo,
  videoUrl,
  type,
  textColor,
  id = "",
  upc = "",
  displayReviews,
  paSmartProductId,
  paRetailerCategory,
  paUPC,
  ctaReadMore,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  isRelatedArticles = false,
  preferPerformance= false,
}) => {
  const { readMore } = useContext(LocalizedContext).sanityAccessibility || {};
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "10px 0px"
  });

  const linkedItem = (
    <>
      {heading && (
        <>
          <div className="title subhead">{heading}</div>
          {type === "article" && (
            <div style={{ color: textColor }} className="readMore">
              <Accessibility location={readMore} />
            </div>
          )}
          {type === "productV2" && displayReviews && (
            <ProductRatings productName={heading} upc={paUPC} template="pdp" />
          )}
        </>
      )}
    </>
  );

  const renderLinkedSlide = () => {
    if (type === "article") {
      return (
        <ArticleCard
          key={heading}
          img={image}
          title={heading}
          description={description}
          mainVideo={mainVideo}
          link={link}
          inView={inView}
          isRelatedArticles={isRelatedArticles}
          preferPerformance={preferPerformance}
          ctaReadMore={ctaReadMore}
        />
      );
    }
    if (type === "look") {
      return (
        <LookCard
            id={heading}
            link={link}
            img={image}
            title={heading}
            linkCard={true}
            isRelatedArticles={isRelatedArticles}
            preferPerformance={preferPerformance}
            inView={inView}
        />
      );
    }
    if (type === "collection") {
      return (
        <CollectionCard
          key={heading}
          img={image}
          title={heading}
          description={description}
          link={link}
          inView={inView}
          preferPerformance={preferPerformance}
        />
      );
    }
    if (type === "productV2") {
      return (
        <ProductCard
          key={heading}
          img={image}
          title={heading}
          link={link}
          paSmartProductId={paSmartProductId}
          paRetailerCategory={paRetailerCategory}
          paUPC={paUPC}
          inView={inView}
          onClick={onClick}
          preferPerformance={preferPerformance}
        />
      );
    }

    return (
      <Link _id={id} to={link as string} onClick={onClick} className="h-100">
        {linkedItem}
      </Link>
    );
  };

  const renderUnlinkedSlide = () => {
    if (type === "tiktok") {
      return <TikTokEmbed url={videoUrl} width={325} />;
    }
    if (type === "productFeature") {
      return <ProductFeatureCard key={heading} img={image} title={heading} description={description} preferPerformance={preferPerformance} />;
    }
    return <></>;
  };

  return (
    <div className="d-lg-flex" ref={ref}>
      {link ? renderLinkedSlide() : renderUnlinkedSlide()}
    </div>
  );
};
export default Slide;
