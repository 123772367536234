import React, { useEffect, useState } from "react";
import { LocalisedPageContext } from "../../types/PageTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, LocalizedContextInterface, Seo, Slug } from "../../types/SanityTypes";
// import LookSlider from "../../components/LookSlider";
import ArticleSlider from "../../components/ArticleSlider";
import ProductSliderV2 from "../../components/ProductSliderV2";
import ArticleProductsPopup from "../../components/ArticleProductsPopup";
import { event59 } from "../../analytics/event59";
import Look from "../../components/Look";
import "./styles.scss";
import { Helmet } from "react-helmet";

// Expected results of the graphql query made by this component.
type ArticleQueryProps = {
  sanityLook: ArticleInterface["data"];
  sanityRelatedArticles: ArticleInterface["data"];
} & LocalizedContextInterface;

interface ArticleRecommendation {
  _id: string;
  _rawHeroImage: ImageInterface;
  slug: { current: string };
  subheading: string;
  headline: string;
  parentSection: Slug;
  rawHeroImage?: ImageInterface | undefined;
  teaser?: {
    _rawTeaserHeroImage: ImageInterface;
  };
}

// interface LookRecommendation {
//   _id: string;
//   _rawHeroImage: ImageInterface;
//   slug: { current: string };
//   subheading: string;
//   headline: string;
//   parentSection: Slug;
//   rawHeroImage?: ImageInterface | undefined;
//   teaser?: {
//     _rawTeaserHeroImage: ImageInterface;
//   };
// }

export interface ArticleFromTextInterface {
  productsRecommendation?: any;
  headline?: string;
  _id?: string;
  tripleHeadline?: boolean;
  _rawHeroImage?: ImageInterface;
  _rawTextBody?: any;
  publishedAt?: string | undefined;
  slug?: { current: string };
  name?: string;
  parentPage?: { name: string };
  parentSection?: Slug | undefined;
  articleRecommendation?: ArticleRecommendation[];
  // lookRecommendation?: LookRecommendation[];
  socialShare?: boolean;
  seo?: Seo;
  mainVideo?: string | undefined;
}

type RemoveDuplicity = (array: any[]) => any[];

interface ProductSectionInterface {
  heading: string | undefined;
  content: {
    _id: string;
    _rawPaImages: ImageInterface[];
    title: string;
    url: string;
    _rawImage: ImageInterface;
    SubBrand: { name: string };
    variant?:
    | {
      primaryColor?: { hex?: string | undefined } | undefined;
    }
    | undefined;
    name: string;
    displayReviews: boolean;
    slug: { current: string };
    productCategory: { name: string };
    paUPC: string;
    paRetailerCategory: string;
    paSmartProductId: string;
    images?: ImageInterface[] | undefined;
  };
}

// Value of `context` in the object passed to the `createPage()` function used to create this page.
type ArticlePageContext = LocalisedPageContext;

interface LookDetailsInterface {
  data: ArticleQueryProps;
  pageContext: ArticlePageContext;
  closeLookDetails?: () => void;
  isPage?: boolean;
}

const LookDetails = ({ data, pageContext, closeLookDetails, isPage }: LookDetailsInterface) => {
  const article = data.sanityLook;

  const articleRecommendation = data.sanityLook.articleRecommendation;
  const articleRecommendationLabel = data.sanityLabels?.articleSliderHeading;

  // const lookRecommendation = data.sanityLook.lookRecommendation;
  // const lookRecommendationLabel = 'More looks you’ll love';

  const articleShareLabel = data.sanityLabels?.articleShare;
  const productsRecommendation = data.sanityLook?.productsRecommendation;

  const articleLabels = data.sanityLabels;
  const [popupOpen, setPopupOpen] = useState(false);
  const [sticky, setSticky] = useState("");

  const pageTitle = article.seo?.metaTitle || article.headline;
  const seoDescription = article.seo?.metaDescription;
  const { htmlLang } = useSiteMetadata(pageContext.language);
  const { siteUrl } = useSiteMetadata(pageContext.language);
  const { slug } = article.slug.current;

  const breadCrumbPages = [article.headline];
  if (article.parentSection?.name) {
    breadCrumbPages.unshift(article.parentSection.name);
  }

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  const removeDuplicity = (array: any[]) => {
    return array.filter((item, index, arr) => {
      const c = arr.map(({ id }) => id);
      return index === c.indexOf(item.id);
    });
  };

  const featuredProducts = (article: ArticleFromTextInterface, removeDuplicity: RemoveDuplicity) => {
    return article?._rawTextBody?.reduce((acc: any[], block: { markDefs: { reference: any }[] }) => {
      block.markDefs &&
        block.markDefs.map(({ reference }) => {
          if (reference && reference._type === "productV2") {
            reference.slug.current = reference.slug.current.replace(/^\d+\//, "");
            reference._rawPaImages = reference.images;
            acc.push(reference);
          }
        });
      return removeDuplicity(acc);
    }, []);
  };

  const productsFromText = featuredProducts(article, removeDuplicity);

  useEffect(() => {
    event59(article.headline);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Escape" && closeLookDetails) {
        closeLookDetails();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeLookDetails]);
  useEffect(() => {
    const closeLookDetails = document.querySelector(".closeLookDetails");
    const articleWrapper = document.getElementById("articleWrapper");

    if (articleWrapper) {
      articleWrapper.style.top = `${articleWrapper.offsetTop.toString()}px`;

      if (closeLookDetails) {
        closeLookDetails.addEventListener("animationend", (e: any) => {
          const scrollTo = window.scrollY;
          articleWrapper.style.top = `${scrollTo}px`;
          articleWrapper.classList.add("open");

          // document.body.style.overflow = 'hidden';
          // setTimeout(() => {
          //   articleWrapper.style.transition = "top 0s";
          //   articleWrapper.classList.add("fixed");
          // }, 1500);
        });
      }
    }
  }, []);

  return (
    <div className={`look-wrapper ${isPage ? "look-wrapper--page" : ""}`} id="articleWrapper">
      {article.aiContent && <Helmet>
        <meta property="Samantha" content="True"></meta>
      </Helmet>}
      <main className="article-children">
        {!isPage && (
          <button className="closeLookDetails" onClick={closeLookDetails}>
            <span></span>
          </button>
        )}

        <Look
          data={article}
          data-testid="article"
          articleRecommendationLabel={data.sanityLabels?.articleSliderHeading as string}
          articleShareLabel={articleShareLabel as string}
        />
        {productsFromText?.length !== 0 && <ProductSliderV2 content={productsFromText} />}

        {productsFromText?.length === 0 &&
          productsRecommendation &&
          productsRecommendation.map((section: ProductSectionInterface, index: string) => (
            <ProductSliderV2
              key={"productSection" + index}
              heading={section.heading}
              isVariant={true}
              content={section.content}
            />
          ))}
        {popupOpen && (
          <ArticleProductsPopup isOpen={popupOpen} onClose={closePopup}>
            {productsFromText?.length !== 0 && <ProductSliderV2 heading="Products used" content={productsFromText} />}
            {productsFromText?.length === 0 &&
              productsRecommendation &&
              productsRecommendation.map((section: ProductSectionInterface, index: string) => (
                <ProductSliderV2
                  heading={section.heading}
                  key={"productSectionPopup" + index}
                  content={section.content}
                />
              ))}
          </ArticleProductsPopup>
        )}

        {articleRecommendation && articleRecommendation?.length > 2 && (
          <ArticleSlider
            isVariant={true}
            heading={articleRecommendationLabel}
            content={articleRecommendation}
            isRelatedArticles={true}
          />
        )}
        {/* {lookRecommendation && lookRecommendation?.length > 2 && (
          <LookSlider
            isVariant={true}
            heading={lookRecommendationLabel}
            content={lookRecommendation}
            isRelatedArticles={true}
          />
        )} */}

        {!isPage && (
          <button className="modal-close" onClick={closeLookDetails}>
            close
          </button>
        )}
      </main>
    </div>
  );
};

export default LookDetails;
