import React, { FunctionComponent } from "react";
import { navigate } from 'gatsby';
import Picture from "../Picture";
import Play from "../Play";
import { Link } from "../Link";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";
import ExternalLink from "../ExternalLink";
import Accessibility from "../Accessibility";

export interface ArticleCardInterface {
  title: string;
  as?: string;
  description?: string;
  mainVideo?: string;
  img: ImageInterface;
  inView?: boolean;
  link: string;
  isRelatedArticles?: boolean;
  preferPerformance?: boolean;
  ctaReadMore?: string;
}

const ArticleCard: FunctionComponent<ArticleCardInterface> = ({
  img,
  title,
  link,
  description,
  mainVideo,
  inView,
  as = "article",
  isRelatedArticles,
  preferPerformance = false,
  ctaReadMore
}) => {
  const articleCardImage = {
    src: `${img?.asset?.url}?w=384&h=232&fit=crop&auto=format&q=80`,
    alt: `${img?.alt}`,
    sources: [
      {
        srcset: [
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 1x`,
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (max-width: 767px)`
      },
      {
        srcset: [
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 1x`,
          `${img?.asset?.url}?w=768&h=464&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (min-width: 768px)`
      }
    ],
    maintainAspectRatio: true,
    size: {
      width: 384,
      height: 232
    },
    lqip: img?.asset?.metadata?.lqip
  };

  const handleNavigate = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${link}/`)
    }
  }

  const navigateToLink = (event) => {
    if (event.target.closest('a')) {
      return;
    }
    const link = event.currentTarget.querySelector('a');
    if (link) {
      link.click();
    }
  };

  return (
    <>
      {!link.includes('http') ? (
          <article 
            className="article-card card rounded-0 h-100"
            onClick={navigateToLink}
          >
            <div className="article-card__picture-wrapper">
              {img && (
                <Picture
                  src={articleCardImage.src}
                  alt={articleCardImage.alt}
                  className="card-img-top"
                  sources={articleCardImage.sources}
                  size={articleCardImage.size}
                  maintainAspectRatio={articleCardImage.maintainAspectRatio}
                  loading={preferPerformance ? "eager" : "lazy"}
                  lqip={articleCardImage.lqip}
                  inView={inView}
                />
              )}
              {mainVideo && <Play onKeyDown={handleNavigate} />}
            </div>
            <div className={`article-card__wrapper mt-3`}>
              <p className="article-card__title card-title keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>{title}</p>
              <p className="article-card__description" >{description}</p>
              {as === "article" && !link.includes('http') ? (
                <Link
                  _id={title}
                  to={link}
                  asButton="secondary"
                  className="d-inline-block text-uppercase"
                  data-analytics-event4
                  aria-label={`Read Article ${title}`}
                >
                  {ctaReadMore ? ctaReadMore : 'Read Article'}
                </Link>
              ) : (
                <a className="d-inline-block text-uppercase c-link__button c-link__button--secondary" href={link} target="_blank" rel="noreferrer"> {ctaReadMore ? ctaReadMore : 'Read Article'}</a>
              )}
            </div>
          </article>
      ) : (
        <a href={link} target="_blank" rel="noreferrer" className="external-card">
        <article className="article-card card rounded-0 h-100">
          <div className="article-card__picture-wrapper">
            {img && (
              <Picture
                src={articleCardImage.src}
                alt={articleCardImage.alt}
                className="card-img-top"
                sources={articleCardImage.sources}
                size={articleCardImage.size}
                maintainAspectRatio={articleCardImage.maintainAspectRatio}
                loading={preferPerformance ? "eager" : "lazy"}
                lqip={articleCardImage.lqip}
                inView={inView}
              />
            )}
            {mainVideo && <Play onKeyDown={handleNavigate} />}
          </div>
          <div className={`article-card__wrapper mt-3`}>
            <p className="article-card__title card-title keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>{title}</p>
            <p className="article-card__description" >{description}</p>
            {as === "article" && 
            <a className="d-inline-block text-uppercase c-link__button c-link__button--secondary" href={link} target="_blank" rel="noreferrer">{ctaReadMore ? ctaReadMore : 'Read Article'}</a>}
             {location ? <Accessibility location={title} openNewWindow={true} /> : <Accessibility openNewWindow={true} />}
          </div>
        </article> 
        </a>
      )}

    </>

  );
};

export default ArticleCard;
