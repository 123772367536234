import React, { FunctionComponent } from "react";
import { navigate } from 'gatsby';
import Picture from "../Picture";
import { Link } from "../Link";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";

export interface CollectionCardInterface {
  title: string;
  description?: string;
  as?: string;
  img: ImageInterface;
  inView?: boolean;
  link: string;
}

const CollectionCard: FunctionComponent<CollectionCardInterface> = ({
  img,
  title,
  description,
  link,
  inView,
  as = "collection"
}) => {
  const collectionCardImage = {
    src: `${img?.asset.url}?w=768&h=768&fit=crop&auto=format&q=80`,
    alt: `${img?.alt}`,
    sources: [
      {
        srcset: [
          `${img?.asset.url}?w=768&h=768&fit=crop&auto=format&q=80 1x`,
          `${img?.asset.url}?w=768&h=768&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (max-width: 767px)`
      },
      {
        srcset: [
          `${img?.asset.url}?w=768&h=768&fit=crop&auto=format&q=80 1x`,
          `${img?.asset.url}?w=768&h=768&fit=crop&auto=format&q=80 2x`
        ],
        media: `screen and (min-width: 768px)`
      }
    ],
    maintainAspectRatio: false,
    size: {
      width: 384,
      height: 384
    },
    lqip: img?.asset?.metadata?.lqip
  };

  const handleNavigate = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${link}/`)
    }
  }

  const navigateToLink = (event) => {
    if (event.target.closest('a')) {
      return;
    }
    const link = event.currentTarget.querySelector('a');
    if (link) {
      link.click();
    }
  };

  return (
    <article 
      className="collection-card card rounded-0 h-100"
      onClick={navigateToLink}
    >
      <div className="collection-card__picture-wrapper">
        {img && (
          <Picture
            src={collectionCardImage.src}
            alt={collectionCardImage.alt}
            className="card-img-top"
            sources={collectionCardImage.sources}
            size={collectionCardImage.size}
            maintainAspectRatio={collectionCardImage.maintainAspectRatio}
            loading="lazy"
            lqip={collectionCardImage.lqip}
            inView={inView}
          />
        )}
      </div>
      <div className={`collection-card__wrapper`}>
        <div className={`collection-card__wrapper-text`}>
          <p className="collection-card__title card-title">{title}</p>
          {
            description && (
              <p className="collection-card__description">{description}</p>
            )
          }
        </div>
        {as === "collection" && (
          <div>
            <Link
              _id={title}
              to={link}
              asButton="secondary"
              className="d-inline-block text-uppercase"
              data-analytics-event2
              aria-label={`View collection ${title}`}
            >
              View collection
            </Link>
          </div>
        )}
      </div>
    </article>
  );
};

export default CollectionCard;
