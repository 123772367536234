import React, { FunctionComponent } from "react";
import SliderBlock from "../SliderBlock";
import Section from "../Section";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import { Link } from "../Link";
import "./styles.scss";

export interface ArticleSliderInterface {
  heading?: string;
  CTAReadMore?: string;
  bgColor?: {
    title: string;
  };
  preferPerformance: boolean;
  isVariant?: boolean;
  content: {
    _id: string;
    __typename: string;
    _rawHeroImage: ImageInterface;
    slug: {
      current: string;
    };
    headline: string;
    parentSection: Slug & {
      parentSection?: Slug;
    };
    mainVideo: string;
    // For Preview
    heroImage?: ImageInterface;
  }[];
  ctaLabel?: string;
  ctaLink?: any;
  centerTiles?: boolean;
  themeColor?: string;
  isRelatedArticles?: boolean;
}

const ArticleSlider: FunctionComponent<ArticleSliderInterface> = ({
  heading,
  CTAReadMore,
  bgColor,
  content,
  themeColor,
  ctaLabel,
  isVariant,
  ctaLink,
  centerTiles = false,
  isRelatedArticles = false,
  preferPerformance = false
}) => {
  const titleColor = themeColor?.toLowerCase() || bgColor?.title?.toLowerCase();

  const colorList: { [key: string]: string } = {
    purple: "var(--purple-light-color)",
    orange: "var(--orange-bright-color)",
    pink: "var(--pink-bright-color)",
    teal: "var(--teal-color)",
    primary: "var(--primary-color)",
    red: "var(--orange-red-color)",
    blue: "var(--blue-dark-color)"
  };
  return (
    <Section
      theme={bgColor}
      heading={heading}
      data-testid="article-slider"
      className={`${
        centerTiles
          ? `article_slider article_slider-${bgColor} center-tiles`
          : `article_slider article_slider-${bgColor}`
      }`}
    >
      {content && (
        <SliderBlock
          isVariant={isVariant}
          data={content}
          textColor={colorList[titleColor as string]}
          type="article"
          isRelatedArticles={isRelatedArticles}
          preferPerformance={preferPerformance}
          ctaReadMore={CTAReadMore}
        />
      )}
      {ctaLabel && ctaLink && (
        <div className="article-slider__cta">
          <Link
            to={
              ctaLink?.parentPage
                ? `${ctaLink.parentPage.slug.current}/${ctaLink.slug.current}}`
                : ctaLink?.slug.current
            }
          >
            {ctaLabel}
          </Link>
        </div>
      )}
    </Section>
  );
};

export default ArticleSlider;
