import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel-jsm";
import "react-multi-carousel-jsm/lib/styles.css";
import { useInView } from "react-intersection-observer";
import { RightTriangleArrow } from "../../images/icons/triangleRight";
import { LeftTriangleArrow } from "../../images/icons/triangleLeft";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import Slide from "../Slide";
import { slug } from "../../utils/slug-generator";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event12 } from "../../analytics/event12";
import { event66 } from "../../analytics/event66";
import { ProductFeaturesSliderInterface } from "../ProductFeaturesSlider";
import arrowleft from "../../images/icons/arrow-left.png";
import arrowright from "../../images/icons/arrow-right.png";

interface ArticleSliderInterface {
  _id: string;
  __typename: string;
  _rawHeroImage: ImageInterface;
  slug: {
    current: string;
  };
  headline: string;
  teaser?: {
    _rawTeaserHeroImage: ImageInterface;
  };
  subheading: string;
  mainVideo?: string;
  parentSection?: Slug & {
    parentSection?: Slug;
  };
  // For Preview
  heroImage?: ImageInterface;
  banner?: {
    headline?: string;
    shortIntro?: string;
    _rawHeroImage?: ImageInterface;
  };
  title?: string;
  description?: string;
  externalLink?: string;
  image?: {
    _rawImage?: ImageInterface;
  };
}

interface CollectionSliderInterface {
  banner: {
    headline: string;
    subHeadline: string;
    _rawHeroImage: ImageInterface;
  };
  slug: {
    current: string;
  };
  parentPage: Slug & {
    parentPage?: Slug;
  };
}

interface ProductSliderV2Interface {
  _id: string;
  _rawPaImages: ImageInterface[];
  _rawImages: ImageInterface[];
  subBrand: {
    name: string;
  };
  displayReviews: boolean;
  name: string;
  slug: {
    current: string;
  };
  variant?: {
    primaryColour?: {
      hex?: string;
    };
  };
  productCategory: {
    name: string;
  };
  paUPC: string;
  paRetailerCategory: string;
  paSmartProductId: string;
  // For Preview
  images?: ImageInterface[];
  sliderType: {
    name: string;
    description: string;
  };
}

interface TiktokSliderInterface {
  _type: string;
  name: string;
  video: string;
}

type SliderContentUnionType =
  | ArticleSliderInterface[]
  | CollectionSliderInterface[]
  | ProductSliderV2Interface[]
  | TiktokSliderInterface[]
  | ImageInterface[];

export interface SliderBlockInterface {
  data: SliderContentUnionType;
  textColor?: string;
  type: string;
  variation?: string;
  _rawImage?: ImageInterface;
  url?: string;
  title?: string;
  isRelatedArticles?: boolean;
  preferPerformance?: boolean;
  isVariant?: boolean;
  ctaReadMore?: string;
}

const SliderBlock: FunctionComponent<SliderBlockInterface> = ({
  data,
  type,
  textColor,
  isRelatedArticles,
  preferPerformance = false,
  ctaReadMore,
  isVariant
}) => {
  const { sanityAccessibility, sanitySiteSettings } = useContext(LocalizedContext);
  const { previous, next } = sanityAccessibility || {};
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [width, setWidth] = useState(992);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: isVariant ? 3 : data?.length > 3 ? 4 : 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: isVariant ? 2 : 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: isVariant ? 1 : 1
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  useEffect(() => {
    setIsDarkTheme(document && document.querySelectorAll(".dark-theme")?.length > 0 ? true : false);
  }, []);

  const renderSlides = () => {
    if (type === "article") {
      return (data as ArticleSliderInterface[]).map((item: ArticleSliderInterface, index: number) => {
        if (item.__typename == "SanityArticle" || item.__typename == undefined) {
          if (isDarkTheme) {
            const articleSlider = document && document.querySelector(".article_slider");
            articleSlider?.classList.add("dark-theme");
          }

          const image = item._rawHeroImage || item?.heroImage;
          const parentPath = item.parentSection?.parentSection
            ? `${item.parentSection?.parentSection.slug.current}/${item.parentSection?.slug.current}`
            : item.parentSection?.slug.current;
          return (
            <React.Fragment key={`${index}`}>
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={`${parentPath}/${item.slug ? item.slug?.current : ""}`}
                image={image}
                heading={item.headline}
                description={item.subheading}
                mainVideo={item.mainVideo}
                type={"article"}
                textColor={textColor}
                inView={inView}
                id={item._id}
                isRelatedArticles={isRelatedArticles}
                preferPerformance={preferPerformance}
                ctaReadMore={ctaReadMore}
              />
            </React.Fragment>
          );
        }
        if (item.__typename == "SanitySearchListingPage") {
          return (
            <React.Fragment key={`${index}`}>
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={item.slug.current}
                image={item.banner?._rawHeroImage}
                heading={item.banner?.headline}
                description={item.banner?.shortIntro}
                mainVideo={item.mainVideo}
                type={"article"}
                textColor={textColor}
                inView={inView}
                id={item._id}
                isRelatedArticles={false}
                preferPerformance={preferPerformance}
                ctaReadMore={ctaReadMore}
              />
            </React.Fragment>
          );
        }
        if (item.__typename == "SanityExternalCard") {
          return (
            <React.Fragment key={`${index}`}>
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={item.externalLink}
                image={item.image?._rawImage}
                heading={item.title}
                description={item.description}
                mainVideo={item.mainVideo}
                type={"article"}
                textColor={textColor}
                inView={inView}
                id={item._id}
                isRelatedArticles={false}
                preferPerformance={preferPerformance}
                ctaReadMore={ctaReadMore}
              />
            </React.Fragment>
          );
        }
        if (item.__typename == "SanityArticleLandingPage") {
          return (
            <React.Fragment key={`${index}`}>
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={item.slug.current}
                image={item.banner?._rawHeroImage}
                heading={item.banner?.headline}
                description={item.banner?.shortIntro}
                mainVideo={item.mainVideo}
                type={"article"}
                textColor={textColor}
                inView={inView}
                id={item._id}
                isRelatedArticles={false}
                preferPerformance={preferPerformance}
                ctaReadMore={ctaReadMore}
              />
            </React.Fragment>
          );
        }
      });
    }
    if (type === "collection") {
      return (data as CollectionSliderInterface[]).map((item: CollectionSliderInterface, index: number) => {
        const image = item.banner._rawHeroImage;
        const parentPath = item?.parentPage?.slug.current;
        return (
          <React.Fragment key={`${index}`}>
            <Slide
              key={index}
              itemPosition={`${index}`}
              link={`${parentPath}/${item.slug ? item.slug?.current : ""}`}
              image={image}
              heading={item.banner.headline}
              description={item.banner.subHeadline}
              type={"collection"}
              inView={inView}
              id={item.banner.headline}
              preferPerformance={preferPerformance}
            />
          </React.Fragment>
        );
      });
    }
    if (type === "productV2") {
      return (data as ProductSliderV2Interface[]).map((item: ProductSliderV2Interface, index: number) => {
        const onClick = () =>
          event12(
            item.paSmartProductId,
            item.name,
            item?.productCategory?.name,
            sanitySiteSettings.brandName,
            index,
            "Product Slider"
          );

        return (
          <React.Fragment key={`${index}`}>
            <Slide
              key={index}
              itemPosition={`${index}`}
              link={`${item?.parentPage.slug.current}/${slug(item.paRetailerCategory)}/${item.slug.current}`}
              image={(item._rawPaImages && item._rawPaImages[0]) || (item._rawImages && item._rawImages[0])}
              heading={item.name}
              inView={inView}
              id={item._id}
              isVariant={isVariant}
              paSmartProductId={item.paSmartProductId}
              paRetailerCategory={item.paRetailerCategory}
              paUPC={item.paUPC}
              type="productV2"
              displayReviews={item.displayReviews}
              onClick={onClick}
              preferPerformance={preferPerformance}
            />
          </React.Fragment>
        );
      });
    }
    if (type === "tiktok") {
      return (data as TiktokSliderInterface[]).map((item: TiktokSliderInterface, index: number) => {
        return (
          <React.Fragment key={`${index}`}>
            <Slide
              key={index}
              videoUrl={item.video}
              itemPosition={`${index}`}
              type={"tiktok"}
              inView={inView}
              id={`${index}`}
            />
          </React.Fragment>
        );
      });
    }
    if (type === "product-features") {
      return (data as ProductFeaturesSliderInterface[]).map(
        (feature: ProductFeaturesSliderInterface, index: number) => {
          return (
            <React.Fragment key={`${index}`}>
              {!feature ? (
                <Slide key={index} itemPosition={`${index}`} inView={inView} preferPerformance={preferPerformance} />
              ) : (
                <Slide
                  key={index}
                  itemPosition={`${index}`}
                  image={feature._rawImage}
                  inView={inView}
                  heading={feature.title}
                  description={feature.description}
                  type="productFeature"
                  preferPerformance={preferPerformance}
                />
              )}
            </React.Fragment>
          );
        }
      );
    }
    if (type === "look") {
      return (data as ArticleSliderInterface[])?.map((item: ArticleSliderInterface, index: number) => {
        const image = item.teaser?._rawTeaserHeroImage || item?._rawHeroImage;
        const parentPath = item.parentSection?.parentSection
          ? `${item.parentSection?.parentSection.slug.current}/${item.parentSection?.slug.current}`
          : item.parentSection?.slug.current;
        return (
          <React.Fragment key={`${index}`}>
            <Slide
              key={index}
              itemPosition={`${index}`}
              link={`${parentPath}/${item.slug ? item.slug?.current : ""}`}
              image={image}
              heading={item.headline}
              description={item.subheading}
              mainVideo={item.mainVideo}
              type={"look"}
              textColor={textColor}
              inView={inView}
              id={item._id}
              isRelatedArticles={isRelatedArticles}
              preferPerformance={preferPerformance}
            />
          </React.Fragment>
        );
      });
    }
  };

  const CustomRightArrow = () => {
    return (
      <button
        className="react-multi-carousel__arrow react-multi-carousel__arrow--right"
        onClick={() => event66(next ? next : "slider next")}
      >
        <RightTriangleArrow />
        <Accessibility location={next} />
      </button>
    );
  };

  const CustomLeftArrow = () => {
    return (
      <button
        className="react-multi-carousel__arrow react-multi-carousel__arrow--left"
        onClick={() => event66(previous ? previous : "slider next")}
      >
        <LeftTriangleArrow />
        <Accessibility location={previous} />
      </button>
    );
  };

  return (
    <div ref={ref} className="slider-block" data-testid="slider-block">
      <div>
        <div data-testid="swiper">
          <Carousel
            showDots={width > 1024 ? data.length > 4 : true}
            responsive={responsive}
            customRightArrow={CustomRightArrow()}
            customLeftArrow={CustomLeftArrow()}
          >
            {renderSlides()}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default SliderBlock;
