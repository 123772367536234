import React, { FunctionComponent, useState } from "react";
import { navigate, graphql, useStaticQuery } from "gatsby";
import Picture from "../Picture";
import Play from "../Play";
import { Link } from "../Link";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";
import ExternalLink from "../ExternalLink";
import Accessibility from "../Accessibility";
import LookDetails from "../LookDetails";

export interface ArticleCardInterface {
  title: string;
  as?: string;
  description?: string;
  mainVideo?: string;
  img: ImageInterface;
  inView?: boolean;
  link: string;
  isRelatedArticles?: boolean;
  preferPerformance?: boolean;
  ctaReadMore?: string;
  linkCard?: boolean;
  id?: string;
}

const LookCard: FunctionComponent<ArticleCardInterface> = ({
  article,
  data,
  pageContext,
  img,
  title,
  link,
  description,
  mainVideo,
  inView,
  as = "article",
  isRelatedArticles,
  preferPerformance = false,
  ctaReadMore,
  linkCard = true,
  handleCardClick,
  currentOpenLook,
  handleCloseClick,
  id
}) => {
  const articleCardImage = {
    src: `${img?.asset?.url}?w=600&h=980&auto=format&q=100`,
    alt: `${img?.alt}`,
    sources: [
      {
        srcset: [
          `${img?.asset?.url}?w=600&h=980&auto=format&q=100 1x`,
          `${img?.asset?.url}?w=600&h=980&auto=format&q=100 2x`
        ],
        media: `screen and (max-width: 47.9375rem)`
      },
      {
        srcset: [
          `${img?.asset?.url}?w=600&h=980&auto=format&q=100 1x`,
          `${img?.asset?.url}?w=600&h=980&auto=format&q=100 2x`
        ],
        media: `screen and (min-width: 48rem)`
      }
    ],
    maintainAspectRatio: true,
    size: {
      width: 600,
      height: 980
    },
    lqip: img?.asset?.metadata?.lqip
  };

  const handleNavigate = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${link}/`);
    }
  };

  if (!linkCard) {
    return (
      <div className={`details-wrapper ${id}`}>
        <article
          className={`look-card card border-0 ${
            currentOpenLook === data.sanityLook._id
              ? "card-img-selected"
              : currentOpenLook && currentOpenLook !== data.sanityLook._id
              ? "card-img-white"
              : ""
          }`}
          onClick={() => handleCardClick(data.sanityLook._id, article)}
        >
          {img && (
            <Picture
              src={articleCardImage.src}
              alt={articleCardImage.alt}
              sources={articleCardImage.sources}
              size={articleCardImage.size}
              loading={preferPerformance ? "eager" : "lazy"}
              lqip={articleCardImage.lqip}
              inView={inView}
            />
          )}

          <div className={`look-card__content`}>
            <h3 className="look-card__title card-title keyboard-focus" tabIndex={0}>
              {title}
            </h3>

            {currentOpenLook === data.sanityLook._id && (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 20">
                <path stroke="#000" stroke-width="3" d="m2 2 15 15L32 2" />
              </svg>
            )}
          </div>
        </article>

        {currentOpenLook === data.sanityLook._id && (
          <LookDetails data={data} pageContext={pageContext} closeLookDetails={handleCloseClick} />
        )}
      </div>
    );
  }

  return (
    <>
      {!link.includes("http") ? (
        <Link
          to={link}
          className="underline-none article-card__link-wrapper"
          data-analytics-event4
          aria-label={`Read Article ${title}`}
          data-analytics-event63={isRelatedArticles}
          tabIndex={-1}
        >
          <article className="look-card card border-0">
            {img && (
              <Picture
                src={articleCardImage.src}
                alt={articleCardImage.alt}
                className="card-img-top"
                sources={articleCardImage.sources}
                size={articleCardImage.size}
                maintainAspectRatio={articleCardImage.maintainAspectRatio}
                loading={preferPerformance ? "eager" : "lazy"}
                lqip={articleCardImage.lqip}
                inView={inView}
              />
            )}
            {mainVideo && <Play onKeyDown={handleNavigate} />}
            <div className={`look-card__content`}>
              <p className="look-card__title card-title keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>
                {title}
              </p>
            </div>
          </article>
        </Link>
      ) : (
        <a href={link} target="_blank" rel="noreferrer" className="external-card">
          <article className="look-card card rounded-0 h-100">
            {img && (
              <Picture
                src={articleCardImage.src}
                alt={articleCardImage.alt}
                className="card-img-top"
                sources={articleCardImage.sources}
                size={articleCardImage.size}
                maintainAspectRatio={articleCardImage.maintainAspectRatio}
                loading={preferPerformance ? "eager" : "lazy"}
                lqip={articleCardImage.lqip}
                inView={inView}
              />
            )}
            <div className={`look-card__content`}>
              <p className="article-card__title card-title keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>
                {title}
              </p>
              {as === "article" && (
                <a
                  className="d-inline-block text-uppercase c-link__button c-link__button--secondary"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ctaReadMore ? ctaReadMore : "Read Article"}
                </a>
              )}
              {location ? (
                <Accessibility location={title} openNewWindow={true} />
              ) : (
                <Accessibility openNewWindow={true} />
              )}
            </div>
          </article>
        </a>
      )}
    </>
  );
};

export default LookCard;
